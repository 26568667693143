import React, { Component } from 'react';
import { Dialog, DialogTitle, Box } from '@material-ui/core';
import { success_tick} from "../assets"
import { styled } from '@material-ui/styles';

interface SuccessDailogProps {
  open: boolean;
  title: string;
  onCancel: () => void;
}

export default class SuccessPopup extends Component<SuccessDailogProps> {
  render() {
    const { open, title, onCancel } = this.props;
    
    return (
      <CustomDialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Box style={{margin:"auto"}}>
            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
               <img src={success_tick.default}/> 
            </Box>    
            <StylesPropTitle id="alert-dialog-title" >{title}</StylesPropTitle>
        </Box>
        
      </CustomDialog>
    );
  }
}

const StylesPropTitle = styled(DialogTitle)({
  color:"#4F506D",
  paddingTop:"16px",
  paddingBottom:"2px",
  "& .MuiTypography-h6":{
    fontFamily: "Avenir Book",
    fontWeight:350,
    fontSize:"18px",
    lineHeight:"24px",
    letterSpacing:"-0.5%",
    textTransform:"uppercase",
    margin: "auto",
    color:"#696A92",
    wordWrap: "break-word",
    textAlign:"center"
  }
});

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": { 
        gap:"28px",
        background:"transparent",
        minHeight:"240px",
        minWidth:"240px",
        boxShadow:"none"
    },
    "& .MuiBackdrop-root":{
      backgroundColor:"rgba(255, 255, 255, 0.9)"
    }
  });