import React from 'react'
import { StyleSheet } from 'react-native'

type props = {
  carouselImage: string;
  sleekImage: string;
  heading: string;
  description: string;
  firstButtonTitle: string;
  redirectToDashboard: Function;
  secondButtonTitle: string;
}

export default function InvalidModal(props: props) {
  const { carouselImage, sleekImage, heading, description, firstButtonTitle, redirectToDashboard, secondButtonTitle } = props;

    return (
    <>
      <div style={{...styles.invalidContainer, boxSizing:"border-box"}}>
          <div style={styles.leftContainer}>
              <img src={carouselImage} style={{height: "100%", width: "100%"}}/>
          </div>
          <div style={{...styles.rightContainer, boxSizing: "border-box"}}>
              <img src={sleekImage} style={{ width: "81px", height: "27px", marginLeft: "auto"}}/>
              <div style={styles.oopsContainer}>
                  <p style={{...styles.heading, lineHeight:"71px", fontSize: "52px"}}>{heading}</p>
                  <p style={{...styles.invalidText, lineHeight:"13px", fontSize: "18px", fontWeight: "350"}}>{description}</p>
                  <div style={{ display: "flex", flexDirection:"column", marginTop:"50px",  gap: "26px"}}>
                      <button data-test-id="homeButton" onClick={() => redirectToDashboard()} style={{...styles.button, cursor: "pointer", fontWeight: "300", lineHeight: "21px", border: "1px solid #4F506D", borderRadius: "12px", fontSize: "16px", color: "#FFFFFF"}}>{firstButtonTitle}</button>
                      <button style={{...styles.button, cursor: "pointer", fontWeight: "300", lineHeight: "21px", fontSize: "16px",  borderRadius: "12px", border: "1px solid #4F506D", backgroundColor: "#FFFFFF", color:"#4F506D"}}>{secondButtonTitle}</button>
                  </div>
              </div>
          </div>
      </div>
    </>
    )
}

const styles = StyleSheet.create({
    invalidContainer:{
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    leftContainer:{
      width: "100%"
    },
    rightContainer:{
      display: "flex",
      flexDirection: "column",
      padding: "26px",
    },
    oopsContainer:{
      width: "338px",
      marginTop: "160px",
      marginRight: "99px",
      marginLeft: "56px",
      display: "flex",
      flexDirection: "column",
      marginBottom: "290px"
    },
    heading:{
      fontWeight: "300",
      textAlign: "left",
      color: "#4F506D",
      margin: 0,
      fontFamily: "Avenir Light"
    },
    invalidText:{
      textAlign: "left",
      margin: 0,
      marginTop: "10px",
      color: "#4F506D",
      fontFamily: "Avenir Book"
    },
    button:{
      width: "100%",
      padding: "16px",
      backgroundColor: "#4F506D",
      height: "56px",
      fontFamily: "Avenir Next Medium"
    },
    alreadyRegistered:{
      fontWeight: "300",
      textAlign: "center",
      color: "#595959",
      marginTop: "auto"
    }
});