import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogContent,
  styled,
  CircularProgress
} from "@material-ui/core";

import InteractivefaqsSharedController, {Props,configJSON} from "./InteractivefaqsSharedController";
import { background_image, back_button, sleek, carouselImage, } from "./assets";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InvalidModal from "./components/InvalidModal.web";
import  ContactModal  from "../../contactus/src/components/ContactModal"
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PauseIcon from '@material-ui/icons/Pause';
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End

export default class Interactivefaqs extends InteractivefaqsSharedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
  render() {

    if(this.state.renderInvalidScreen)
    {
        return (<InvalidModal carouselImage={carouselImage.default} sleekImage={sleek.default} heading={this.state.invalidScreen.heading} description={this.state.invalidScreen.description} firstButtonTitle={"Home"} redirectToDashboard={this.redirectToDashboard} secondButtonTitle={"Support"} />)
    }

    if(this.state.isLoading){
        return(
            <Loader>
                <CircularProgress />
            </Loader>
        )
    }

    return (
       // Customizable Area Start
     <>
      <FaqContainer>
        <Box>
            <Box className="header">
                <img src={background_image.default} style={{height: "100%", width: "100%", position: "absolute", left:0, top:0, zIndex:"-2"}}/>
                <Box className="headerMenu">
                    <p>pricing</p> <p data-test-id="contactus" onClick={()=>this.setDialogName("contact")}>contact</p> <p>members</p> <img src={back_button.default} style={{ cursor: "pointer" }}/>
                </Box>
                <PlayArrowIcon className="playButton"/>
                <p className="heading">Frequently Asked Questions</p>
            </Box>
        </Box>
        <Box className="bodyContainer">
             <Box className="leftContainer">
                {
                    this.state.faqTypes.map((faqType, index) => (
                        <p key={index} className={this.state.faqType === faqType ? "selected" : ""} data-test-id="faqType" onClick={()=>this.setFaqType(faqType)}>{faqType}</p>
                    ))
                }
             </Box>
             <Box className="rightContainer">
                <div ref={this.contentRef as React.Ref<any>} className="accordionContainer">
                    {
                        this.state.faq && this.state.faq[this.state.faqType] && this.state.faq[this.state.faqType].map((faq:{title: string, content: string, id: number, video: string}, index:number)=>(
                            <Accordion key={faq.id} data-test-id="faqAccordion" onChange={this.handleAccordionChange(`panel${faq.id}`)}>
                            <AccordionSummary
                            expandIcon={<div className="expandContainer"><ExpandMoreIcon className={this.state.isExpanded === `panel${faq.id}` ? "expandableIcon" : ""} /></div>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className="accordionTitle">{faq.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordionDetails">
                            <Typography className="accordionContent" dangerouslySetInnerHTML={{ __html: faq.content }}></Typography>
                            {faq.video && <Box className={ this.state.isVideoPlaying ? "videoContainer" : ""}>
                                <Box className="video">
                                    <CloseIcon onClick={this.handlePopupClose} data-test-id="closePopup" className="closePopup"/>
                                    <video 
                                     muted={this.state.video.muted} 
                                     id={`video-id-${index}`} 
                                     disablePictureInPicture
                                     onClick={ this.state.isVideoPlaying ? this.handlePlayPause : undefined}
                                     controlsList="nodownload nofullscreen"
                                     >
                                        <source src={faq.video} type="video/mp4"/>
                                     </video>
                                    {!this.state.isVideoPlaying && <p data-test-id="openVideoPopup" onClick={()=>this.playAccordionVideo(index)} className="playVideoButton"><PlayArrowIcon/></p>}
                                    <Box className="controls">
                                        <div className="play-pause" data-test-id="play-pause" onClick={this.handlePlayPause}>{ this.state.video.isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }</div>
                                        <div className="mute" data-test-id="muteButton" onClick={this.handleVideoMute}>{ this.state.video.muted ? <VolumeOffIcon/> : <VolumeUpIcon/> }</div>
                                        <div className="dragContainer">
                                            <DragInput
                                                data-test-id="custom-slider"
                                                type="range"
                                                min="0"
                                                step="0.1"
                                                className="custom-slider"
                                                max={this.state.video.duration || 100}
                                                value={this.state.video.currentTime || 0}
                                                onChange={this.handleSliderChange}
                                                onMouseDown={this.handleMouseDown}
                                                onMouseUp={this.handleMouseUp}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Box>
                                </Box>
                            </Box>}
                            </AccordionDetails>
                        </Accordion>
                        ))
                    }
                </div>
                <Box className="sleekContainer">
                    <img src={sleek.default} />
                    <p>For support, email us at <span>care@sleek.com.</span></p>
                </Box>
             </Box>
        </Box>
      </FaqContainer>
      
      <CustomDialog open={this.state.openDialogName === "contact"} scroll="body" onClose={()=>this.closeDialig()}>
        <DialogContent>
            <ContactModal  
                navigation={this.props.navigation}
                id={this.props.id}
                disableLoggedInFields={false} 
                closeDialog={this.closeDialig}
            />
        </DialogContent>
      </CustomDialog>

      <ImageDialog open={this.state.selectedImage !== ""}>
        <DialogContent>
            <Box className="imageContainer">
                <CloseIcon onClick={this.closeImageDialog} data-test-id="closeImagePopup" className="closePopup"/>
                <img src={this.state.selectedImage} />
            </Box>
        </DialogContent>
      </ImageDialog>
     </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 

const FaqContainer = styled(Box)({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    '& .header':{
        height: "246px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: "35px",
        paddingBottom: "28px",
        boxSizing: "border-box",
        '& .heading':{
            fontSize: "31px",
            fontWeight: "400",
            lineHeight: "42.35px",
            letterSpacing: "-0.01em",
            textAlign: "left",
            color: "#FFFFFF",
            margin: 0,
            marginTop: "auto",
            fontFamily: "Avenir Next Regular"
        }
    },
    '& .headerMenu':{
        display: "flex",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "26px",
        letterSpacing: "0.04em",
        color: "#FFFFFF",
        marginLeft: "auto",
        width: "fit-content",
        gap: "38px",
        '& p':{
            margin:0,
            cursor: "pointer",
            fontFamily: "Avenir Next Medium",
            fontSize: "18px"
        }
    },
    '& .playButton':{
        color: "#FFFFFF4D",
        cursor: "pointer",
        top: "0",
        bottom: "0",
        marginTop: "auto",
        marginBottom: "auto",
        paddingTop: "40px",
        position: "absolute",
        right: "77px",
    },
    '& .playButton.MuiSvgIcon-root':{
        width: "90px",
        height: "90px"
    },
    '& .bodyContainer':{
        display:"flex",
        flexGrow: 1,
        marginTop: "62px",
        '& .leftContainer':{
            padding: "0 72px",
            gap: "30px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "70px"
        },
        '& .leftContainer p':{
            width: "155px",
            height: "80px",
            color: "#B3BFDB",
            borderRadius: "76px",
            boxShadow: "-2px 8px 21px 0px #8E90C082",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            fontSize: "18px",
            fontWeight: "300",
            lineHeight: "26px",
            textAlign: "center",
            cursor: "pointer",
            fontFamily: "Avenir Light"
        },
        '& .leftContainer p:hover':{
            boxShadow: "-2px 8px 21px 0px #8E90C082",
            color: "#4F506D",
            fontWeight: "500",
            fontFamily: "Avenir Next Medium"
        },
        '& .selected':{
            backgroundColor: "#F4F4F9 !important",
            color: "#4F506D !important",
            boxShadow: "2px 5px 9px 0px #D2D5EBEB !important",
            fontWeight: "500 !important",
            fontFamily: "Avenir Next Medium !important"
        },
        '& .rightContainer':{
            zIndex: 2,
            paddingRight: "40px",
            paddingLeft: "32px",
            paddingBottom: "20px",
            height: "calc(100vh - 308px)",
            width: "calc(100% - 300px)",
            minHeight: "100%",
            boxSizing: "border-box",
            '& ::-webkit-scrollbar': {
                width: "10px",
                borderRadius: "40px"
              },
            "& ::-webkit-scrollbar-track": {
                backgroundColor: "#F0F2F8",
                borderRadius: "40px"
            },
            "& ::-webkit-scrollbar-thumb":{
                width: "8px",
                backgroundColor: "#B3BFDB",
                borderRadius: "40px",
            },
            '& .accordionContainer':{
                display: "flex",
                flexDirection: "column",
                gap: "55px",
                height: "calc(100% - 82px)",
                overflow: "auto"
            },
            "*":{
            scrollbarWidth: "thin",
            scrollbarColor: "#B3BFDB #F0F2F8"
            },
            '& .MuiAccordionSummary-root':{
                flexDirection: "row-reverse",
                cursor: "pointer"
            },
            '& .MuiAccordion-root::before':{
                backgroundColor: "transparent"
            },
            '& .MuiPaper-elevation1':{
                border: "none",
                boxShadow: "none"
            },
            '& .accordionTitle':{
                fontSize: "30px",
                fontWeight: 300,
                color: "#4F506D",
                lineHeight: "40.98px",
                fontFamily: "Avenir Light"
            },
            '& .accordionDetails':{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "54px",
                '& .closePopup':{
                    display: "none"
                },
                '& .video':{
                    boxShadow: "0px 4px 12px 0px #00000073",
                    borderRadius: "32px",
                    width: "234px",
                    height: "160px",
                    objectFit: "cover",
                    overflow: "hidden",
                    position: "relative",
                    marginTop: "20px",
                    '& video':{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover"
                    },
                    '& .playVideoButton':{
                        height: "60px",
                        width: "60px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50%",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        '& .MuiSvgIcon-root':{
                            width: "35px",
                            height: "35px",
                        },
                    }
                }
            },
            '& .accordionContent':{
                fontFamily: "Avenir Light",
                fontSize: "18px",
                fontWeight: 300,
                lineHeight: "26px",
                color: "#4F506D",
                overflowWrap: "anywhere",
                '& img':{
                    width: "234px",
                    height: "160px",
                    borderRadius: "32px",
                    cursor: "pointer",
                    boxShadow:" 0px 4px 12px 0px #00000073"
                }
            },
            '& .MuiSvgIcon-root':{
                width: "16px",
                height: "16px",
                color: "#4F506D"
            },
            '& .MuiAccordionSummary-content':{
                margin: 0,
                marginLeft: "26px"
            },
            '& .MuiAccordion-root.Mui-expanded':{
                margin: 0
            },
            '& .MuiAccordionSummary-root.Mui-expanded':{
                minHeight: "48px"
            },
            '& .expandableIcon.MuiSvgIcon-root':{
                width: "24px",
                height: "24px"
            },
            '& .MuiIconButton-root':{
                padding: "0"
            },
            '& .expandContainer':{
                width: "40px", 
                height:"40px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center"
            }
        },
        '& .videoContainer':{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            height: "100vh",
            width: "100%",
            zIndex: 99,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(6px)",
            boxShadow: "0px 40px 60px 0px #0000001F, 0px -4px 24px 0px #0000000D",
            '& .closePopup':{
                display: "flex !important",
                position: "absolute",
                top: "18px",
                right: "18px",
                cursor: "pointer",
                width: "24px !important",
                height: "24px !important",
            },
            '& .video':{
                padding: "44px 44px 12px",
                backgroundColor: "#FFFFFF",
                width: "auto !important",
                height: "auto !important",
                marginTop: "auto !important",
                marginBottom: "71px",
                boxShadow: "0px 40px 60px 0px #0000001F, 0px -4px 24px 0px #0000000D !important",
                '& video':{
                    borderRadius: "22px",
                    width: "701px !important",
                    height: "435px !important",
                },
                '& .controls': {
                    display: "flex",
                    alignItems: "center",
                    margin: "0 25px",
                    marginTop: "10px",
                    position: "relative"
                },
                '& .dragContainer':{
                    width: "100%",
                    marginLeft: "9px"
                },
                '& .mute':{
                    '& .MuiSvgIcon-root': {
                        color: "#4F506D",
                        width: "35px",
                        height: "35px",
                    }
                },
                '& .play-pause':{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    left: 0,
                    right: 0,
                    top: "-67px"
                }
            }
        },
    },
    '& .sleekContainer':{
        marginLeft: "auto",
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
        '& img':{
          marginLeft: "auto"  
        },
        '& p':{
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "24px",
            color: "#B3BFDB",
            marginBottom: 0,
            fontFamily: "Avenir Light"
        },
        '& span':{
            color: "#4F506D"
        }
    }
});


const CustomDialog = styled(Dialog)({
    top: "83px !important",
    '& .MuiBackdrop-root':{
        backgroundColor: "transparent",
        top: "83px",
    },
    '& .MuiDialog-container':{
        backdropFilter: "Blur(6px)",
    },
    '& .MuiDialog-paper':{
        margin: 0,
        position: "absolute",
        right: 100,
        top: 5,
        boxShadow: "none",
        background: "transparent",
        height: "calc(100% - 5px)"
    },
    '& .MuiDialogContent-root':{
        padding: 0,
        height: "100%"
    }
})

const DragInput = styled('input')({
    '&.custom-slider': {
        width: "100%",
        height: "8.5px",
        background: "#F0F2F8",
        colot: "#000000",
        borderRadius: "7px",
        outline: "none",
        margin: 0,
        "-webkit-appearance": "none",
        overflow: "hidden"
    },
    
    '&.custom-slider::-moz-range-thumb': {
        width: "0",
        height: "0",
        borderRadius: "7px",
        background: "#F0F2F8",
        border: "none",
        cursor: "pointer",
    },
    
    "&.custom-slider::-moz-range-track": {
        borderRadius: "5px",
        height: "8.5px",
        background: "#F0F2F8",
    },
      
    "&.custom-slider::-moz-range-progress": {
        backgroundColor: "#4F506D",
        height: "7px",
        borderRadius: "7px"
    },

    "&.custom-slider::-webkit-slider-thumb": {
        width: "0",
        height: "0",
        borderRadius: "7px",
        background: "#F0F2F8",
        border: "none",
        cursor: "pointer",
        "-webkit-appearance": "none",
        boxShadow: "-607px 0 0 607px #4F506D"
    },

    "&.custom-slider::-webkit-slider-runnable-track": {
        borderRadius: "5px",
        height: "8.5px",
        background: "#F0F2F8",
    },

    "&.custom-slider::-webkit-progress-value": {
        backgroundColor: "#4F506D",
        height: "7px",
        borderRadius: "7px",
    }
})

const ImageDialog = styled(Dialog)({
    '& .MuiBackdrop-root':{
        backgroundColor: "transparent",
        backdropFilter: "blur(6px)"
    },
    '& .MuiDialog-paperWidthSm':{
        maxWidth: "none"
    },
    '& .MuiDialog-paperScrollPaper':{
        maxHeight: "none"
    },
    '& .MuiDialogContent-root':{
        padding: 0
    },
    '& .MuiPaper-elevation24':{
        boxShadow: "0px 40px 60px 0px #0000001F, 0px -4px 24px 0px #0000000D",
        borderRadius: "32px",
        marginTop: "auto",
        marginBottom: "71px",
    },
    '& .imageContainer':{
        width: "789px",
        height: "541px",
        padding: "44px",
        boxSizing: "border-box",
        '& img':{
            width: "100%",
            height: "100%",
            borderRadius: "22px"
        }
    },
    '& .closePopup':{
        top: "18px",
        right: "18px",
        width: "24px",
        height: "24px",
        display: "flex",
        color: "#4F506D",
        cursor: "pointer",
        position: "absolute",
    }
})

const Loader = styled('div')({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .MuiCircularProgress-colorPrimary':{
      color: "#4F506D",
    }
})

// Customizable Area End