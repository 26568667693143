import React, { useState ,useEffect} from "react";
import { Container, Box } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import { carousel_display } from "../../../email-account-login/src/assets";

export interface CarouselData {
  id: number;
  type: "carousel_display";
  attributes: {
    id: number;
    title: string;
    sequence_number: number;
    image: string| null;
    video:string| null;
  };
}

export interface CarouselProps {
  data: CarouselData[];
}

const CarosalModel  = ({ data }:CarouselProps) => {
  const [index, setIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showDefaultImage, setShowDefaultImage] = useState(true);

  useEffect(() => {
    setImageLoaded(false);
    setShowDefaultImage(true);
  }, [data]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    setTimeout(() => {
      setShowDefaultImage(false);
    }, 2000);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} style={{height:"100%", display:"grid",alignItems:"center",maxWidth:"98%"}}>
          <Carousel
            autoPlay={true}
            interval={10000}
            animation="fade"
            swipe={true}
            index={index}
            indicators={false}
            navButtonsAlwaysInvisible={true}
          >
            {data.map((item) => (
              <Box key={item.attributes.id} style={styles.carousel}>
                {item.attributes.image ? (
                  <img
                    style={{
                      ...styles.media,
                      opacity: showDefaultImage ? 1 : imageLoaded ? 1 : 0,
                      filter: imageLoaded && !showDefaultImage ? "none" : "blur(10px)",
                      transition: "opacity 2s ease-in-out, filter 1s ease-in-out",
                    }}
                    src={
                      showDefaultImage
                        ? carousel_display.default
                        : item.attributes.image
                    }
                    alt={`Slide ${item.attributes.id}`}
                    onLoad={handleImageLoad}
                  />
                ) : item.attributes.video ? (
                  <video
                    style={styles.media}
                    autoPlay
                    muted
                    loop
                    src={item.attributes.video}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </Box>
            ))}
          </Carousel>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default CarosalModel;

// Theme and Styles
const theme = createTheme({
  palette: {
    primary: { main: "#fff", contrastText: "#fff" },
  },
  typography: {
    h6: { fontWeight: 500 },
    subtitle1: { margin: "20px 0px" },
  },
});

const styles = {
  carousel: {
    flex: 1,
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    width: "100%",
    borderRadius:"24px",
    height:"95vh",
    ObjectFit:"cover",
    transition: "all 0.5s ease",
  },
  indicatorIconWrap: {
    display: "none",
    flexDirection: "row" as "row",
    justifyContent: "center",
    marginTop: 20,
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    display: "flex",
  },
  thumbnail: {
    flex: 1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 100,
  },
};
