import React, { Component } from 'react';
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  btnText?: string
  onCancel: () => void;
  onConfirm: () => void;
}

export default class CancelWarningPopup extends Component<ConfirmDialogProps> {
  render() {
    const { open, title, onCancel, onConfirm, btnText } = this.props;
    
    return (
      <StyledDialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <StyledDialogTitle id="alert-dialog-title" >{title}</StyledDialogTitle>
        <StyledDialogActions>
          <StyledButton style={{ color:"#4F506D",borderRadius:"16px",border:"1px solid #4F506D", width:"96px",height:"48px"}} onClick={onCancel} color="primary">
            Cancel
          </StyledButton>
          <StyledButton style={{ background:"#4F506D",color:"#FFFFFF",borderRadius:"16px",border:"1px solid #4F506D", width:"143px",height:"48px"}} onClick={onConfirm} color="primary" autoFocus>
            {btnText}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        background:"#FFFFFF",
        minHeight:"182px",
        minWidth:"350px",
        borderRadius:"30px",
        gap:"28px"
    }
  });

const StyledDialogTitle = styled(DialogTitle)({
  color:"#4F506D",
  padding:"26px",
  "& .MuiTypography-h6":{
    fontFamily: "Avenir Light",
    fontWeight:300,
    fontSize:"18px",
    lineHeight:"32px",
    letterSpacing:"-0.5%",
    margin: "auto",
    width: "85%",
    wordWrap: "break-word",
    textAlign:"center"
  }
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent:"center",
    gap:"8px",
    paddingBottom:"26px"
});

const StyledButton = styled(Button)({
    color: '#ffffff',
    padding: '12px 20px',
    border: "2px solid #FFFFFF",
    height: "48px",
    width: "162px",
    gap: "8px",
    fontFamily: "Avenir Next Medium",
    textTransform: "capitalize",
    fontSize:"16px",
    lineHeight:"16px",
    alignItems: "center",
    fontWeight:500,
});