import { Box, Button, Input, styled, TextareaAutosize, Typography } from '@material-ui/core';
import React from 'react'
import ContactusController, { Props } from '../ContactusController';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

export default class ContactModal extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render(){
    return (
    <>
      <ContactContainer>
        <Box className='contactFormContainer'>
          <Box className='contactform'>
            { !this.state.isSubmitted ?
            <>
              <Typography className='title'>Let’s talk!</Typography>
              <Box className='fieldsContainer'>
                <Box className="inputField" style={{boxShadow: this.state.errorMessages.nameRequired ? "0px 0px 3px 0px #C5475B" : ''}}>
                  <p className="inputTitle">Name</p>
                  <InputField
                  data-test-id="nameField"
                  name="name"
                  fullWidth={true}
                  disableUnderline={true}
                  value={this.state.contactName}
                  onChange={(e:{target:{value:string}}) => this.setContactName(e.target.value)}
                  />
                </Box>
                <Box className="inputField" style={{boxShadow: this.state.errorMessages.emailRequired || this.state.errorMessages.invalidEmail ? "0px 0px 3px 0px #C5475B" : ''}}>
                  <p className="inputTitle">Email</p>
                  <InputField
                  data-test-id="emailField"
                  name="email"
                  fullWidth={true}
                  disableUnderline={true}
                  value={this.state.contactEmail}
                  onChange={(e:{target:{value:string}}) => this.setContactEmail(e.target.value)}
                  />
                </Box>
                <Box className="inputField" style={{boxShadow: this.state.errorMessages.messageRequired ? "0px 0px 3px 0px #C5475B" : ''}}>
                  <p className="inputTitle">Message</p>
                  <textarea
                  data-test-id="messageField"
                  name="message"
                  value={this.state.contactMessage}
                  onChange={(e:{target:{value:string}}) => this.setContactMessage(e.target.value)}
                  />
                </Box>
              </Box>
              <Box style={{ height: "48px", display: "flex" }}>
              {
                (this.state.errorMessages.emailRequired || 
                this.state.errorMessages.nameRequired || 
                this.state.errorMessages.messageRequired)  && 
                <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                <p>{
                    [ this.state.errorMessages.emailRequired,
                      this.state.errorMessages.nameRequired,
                      this.state.errorMessages.messageRequired
                    ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
                { this.state.errorMessages.invalidEmail && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid email.</p></div>}
              </Box>
              <Box className='buttonsContainer'>
                <Button className="sendButton" data-test-id="submitDetails" onClick={() => this.submitContactDetails()}>Send</Button>
                <Button className='faqButton' disableRipple>FAQ</Button>
              </Box>
            </> :
            <Box className='submittedContainer'>
              {
                this.state.submitResponse === "success" ? 
                <>
                  <Typography className='title'>Thank you!</Typography>
                  <Typography className='description'>We'll respond within 2 business days.</Typography>
                </> :
                <>
                  <Typography className='title'>Oops!</Typography>
                  <Typography className='description'>We've encountered an issue.</Typography>
                  <Typography className='description'>Please try again or contact <span>care@sleek.com.</span></Typography>
                </>
              }
              <Button data-test-id="closeDialog" className="sendButton closeButton" onClick={() => this.props.closeDialog()}>Close</Button>
            </Box>
            }
          </Box>
        </Box>
      </ContactContainer>
    </>
    )
  }
}

const ContactContainer = styled(Box)({
  height: "100%",
  paddingRight: "50px",
  paddingLeft: "50px",
  '& ::-webkit-scrollbar': {
      width: "8px",
      borderRadius: "40px"
    },
  "& ::-webkit-scrollbar-track": {
      backgroundColor: "#F0F2F8",
      borderRadius: "40px"
  },
  "& ::-webkit-scrollbar-thumb":{
      width: "6px",
      backgroundColor: "#B3BFDB",
      borderRadius: "40px",
  },
  '& .contactFormContainer':{
    width: "438px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100% - 70px)",
    padding: "48px 0px",
    borderRadius: "30px",
    boxSizing: "border-box",
    backgroundColor: "#FAFAFA",
    boxShadow: "0px 40px 60px 0px #0000001F, 0px -4px 24px 0px #0000000D",
  },
  '& .contactform':{
    paddingRight: "40px",
    paddingLeft: "40px",
    maxHeight: "calc(100% - 166px)",
    overflowY: "auto"
  },
  '& .title':{
    fontFamily: "Avenir Next Regular",
    fontSize: "36px",
    fontWeight: 400,
    lineHeight: "49.18px",
    color: "#4F506D"
  },
  '& .fieldsContainer':{
    marginTop: "48px",
    gap: "20px",
    display: "flex",
    flexDirection: "column"
  },
  '& .inputField':{
    boxShadow: "0px 4px 9px 0px #0000000F, 0px -1px 14px 0px #00000005",
    borderRadius: "20px",
    padding: "6px 16px",
    position: "relative",
    backgroundColor: "#FFFFFF",
    '& .MuiSvgIcon-root':{
      width: "18px",
      height: "18px"
    },
    '& .MuiIconButton-root':{
      height: "fit-content",
      marginTop: "auto",
      marginBottom: "auto"
    }
  },
  '& .inputTitle':{
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "22px",
    textAlign: "left",
    color: "#787C86",
    margin: 0,
    fontFamily: "Avenir Light"
  },
  '& textarea':{
    height: "113px",
    width: "100%",
    border: "none",
    resize: "none",
    padding: "0",
    margin: "0",
    fontFamily: "Avenir Light",
    fontSize: "16px",
    color: "#4F506D",
    marginBottom: "1px",
    outline: "none"
  },
  '& .buttonsContainer':{
    display: "flex",
    flexDirection: "column",
    gap: "24px"
  },
  '& .sendButton':{
    width: "100%", 
    padding: "16px",
    textTransform: "none", 
    borderRadius: "16px", 
    backgroundColor: "#4F506D",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontWeight: "350px",
    cursor: "pointer",
    fontFamily: "Avenir Next Medium",
    '& .MuiButton-root':{
      fontFamily: "inherit"
    }
  },
  '& .faqButton':{
    fontFamily: "Avenir Next Regular",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    cursor: "pointer",
    padding: 0,
    backgroundColor: "transparent"
  },
  '& .submittedContainer':{
    display: "flex",
    flexDirection: "column",
    height: "539px",
    '& .description':{
      fontFamily: "Avenir Light",
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "26px",
      letterSpacing: "-0.1px",
      '& span':{
        fontFamily: "Avenir Next Medium"
      }
    },
    '& .closeButton':{
      marginTop: "auto",
      marginBottom: "42px"
    }
  },
  '& .errorMessage':{
      display: "flex",
      fontSize: "12px",
      fontWeight: 400,
      color: "#C5475B",
      textAlign: "left",
      marginRight: "auto",
      marginBottom: "10px",
      marginTop: "auto",
      fontFamily: "Avenir Next Regular",
      '& p':{
        margin: 0,
        height: "14px",
        marginLeft: "5px",
      },
      '& .MuiSvgIcon-root':{
        width: "16px",
        height: "16px"
      }
  }
})

const InputField = styled(Input)({
  width: "100%",
  padding: "0",
  margin: "0",
  borderRadius: "20px",
  border: "none",
  backgroundColor: "#FFFFFF",
  fontFamily: "Avenir Light",
  fontSize: "16px",
  color: "#4F506D",
  '& .MuiInputBase-input':{
    padding: 0,
    marginBottom: "1px",
  }
})