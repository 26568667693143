import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
} from "@material-ui/core";
import ForgotPasswordController, {Props} from "./ForgotPasswordController";
import { oops_invalid_link, sleek } from "./assets";

// Customizable Area End

export default class InvalidLink extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
      this.checkStorage()
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
     <>
      <InvalidContainer>
        <Box className="leftContainer">
          <img src={oops_invalid_link.default} style={{height: "100%", width: "100%"}}/>
        </Box>
        <Box className="rightContainer">
          <img src={sleek.default} style={{ width: "81px", height: "27px", marginLeft: "auto"}}/>
          <Box className="oopsContainer">
            <p className="heading">Oops!</p>
            <p className="invalidText">{this.state.invalidText}</p>
            <Box style={{ display: "flex", flexDirection:"column", marginTop:"50px",  gap: "26px"}}>
              <button className="button" data-test-id="resetButton" onClick={this.handleInvalidButtonClick}>{this.state.invalidButton}</button>
              <button className="button" style={{backgroundColor: "#FFFFFF", color:"#4F506D"}}>Support</button>
            </Box>
            <p className="alreadyRegistered" data-test-id="alreadyRegistered" onClick={this.redirectToSignIn}>Already registered? <span>Sign in</span></p>
          </Box>
        </Box>
      </InvalidContainer>
     </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start 

const InvalidContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  fontFamily: 'sans-serif !important',
  boxSizing: "border-box",
    '& .leftContainer':{
      width: "100%",
      height: "100%",
    },
  '& .rightContainer':{
    display: "flex",
    flexDirection: "column",
    padding: "26px",
    boxSizing: "border-box"
  },
  '& .oopsContainer':{
    width: "338px",
    display: "flex",
    flexDirection: "column",
    marginTop: "160px",
    marginBottom:"92px",
    marginRight: "99px",
    marginLeft: "6px"
  },
  '& .heading':{
    fontWeight: "300",
    textAlign: "left",
    color: "#4F506D",
    margin: 0,
    lineHeight:"71.05px", 
    fontSize: "52px",
    fontFamily: "Avenir Light"
  },
  '& .invalidText':{
    textAlign: "left",
    margin: 0,
    marginTop: "10px",
    color: "#4F506D",
    lineHeight:"13px", 
    fontSize: "18px", 
    fontWeight: "350",
    fontFamily: "Avenir Book"
  },
  '& .button':{
    width: "100%",
    padding: "16px",
    backgroundColor: "#4F506D",
    border: "1px solid #4F506D",
    fontWeight: "300",
    lineHeight: "21px",
    cursor: "pointer",
    borderRadius: "12px", 
    fontSize: "18px", 
    color: "#FFFFFF",
    height: "56px",
    fontFamily: "Avenir Light"
  },
  '& .alreadyRegistered':{
    fontWeight: "300",
    textAlign: "center",
    color: "#595959",
    marginTop: "178px",
    fontSize: "16px", 
    lineHeight: "22px", 
    letterSpacing: "0.10000000149011612px", 
    cursor: "pointer",
    marginBottom: "0",
    fontFamily: "Avenir Light",
    '& span':{
      color: "#000000",
      fontFamily: "Avenir Next Medium"
    }
  }
})

// Customizable Area End